@font-face {
  font-family: "doctorSoos";
  src: url("./fonts/Doctor-Soos-Bold.ttf");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "dkLemon";
  src: url("./fonts/DK-Lemon-Bold.ttf");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "museoSans";
  src: url("./fonts/Museo-Sans-Bold.ttf");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "visbyRound";
  src: url("./fonts/Visby-Round-Bold.otf");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "visbyRound";
  src: url("./fonts/Visby-Round.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "proximaNova";
  src: url("./fonts/ProximaNova-Regular.otf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "allisonScript";
  src: url("./fonts/Allison-Script.otf");
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

img {
  user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

textarea:focus,
input:focus,
select:focus {
  outline: none;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
